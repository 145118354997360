import {TimeDuration,Duration} from "typed-duration";

import ProgressInfo from "./progress_info";
import Stopwatch from "../utilities/stopwatch";
import ComponentError from "./component_error";
import ComponentContainer from "../component_container";

const {milliseconds} = Duration;

abstract class Component {
    private _loaded = false;
    private _unloaded = false;
    private _paused = false;

    private _progressStopwatches: Map<number, Stopwatch> = new Map();
    private _progressReported: Map<number, TimeDuration> = new Map();

    private _mapComponentContainer?: ComponentContainer;

    get paused(): boolean {
        return this._paused;
    }

    get unloaded(): boolean {
        return this._unloaded;
    }

    get progressReported(): Map<number, TimeDuration> {
        return new Map(this._progressReported);
    }

    abstract get type(): Function;
    abstract get name(): string;
    abstract load(): Promise<Array<ComponentError>>;
    abstract onUnload(): Promise<void>;
    abstract onPause(): Promise<void>;
    abstract onResume(): Promise<void>;

    startProgress(id: number): void {
        const stopwatch = new Stopwatch();
        stopwatch.start();
        this._progressStopwatches.set(id, stopwatch);
    }

    stopProgress(id: number): void {
        const stopwatch = this._progressStopwatches.get(id);
        if (stopwatch) {
            stopwatch.stop();
            this._progressReported.set(id, milliseconds.of(stopwatch.elapsed));
        }
    }

    async pause(): Promise<void> {
        this._paused = true;
        await this.onPause();
    }

    setDependencyLocked(dependencies: Array<Function>): Promise<void> {
        return this._mapComponentContainer!.setDependencyLocked(this, dependencies);
    }

    setAskForPermissionLock(locked: boolean): Promise<void> {
        return this._mapComponentContainer!.setAskForPermissionLock(this, locked);
    }

    log(message: string): void {
        console.log(`[${this.name}] ${message}`);
        // this._mapComponentContainer!
        //     .getComponent(OverlayComponent)
        //     .then((value) => {
        //         const overlayComponent = value as OverlayComponent;
        //         overlayComponent.mapOverlayController.log(`[${this.name}] ${message}`);
        //     });
    }

    set container(mapComponentContainer: ComponentContainer) {
        if (!this._mapComponentContainer) {
            this._mapComponentContainer = mapComponentContainer;
        }
    }

    get container(): ComponentContainer {
        return this._mapComponentContainer!;
    }

    getComponent<T>(componentType: Function): Promise<T> {
        return this._mapComponentContainer!.getComponent(componentType) as Promise<T>;
    }

    reportProgress(status: string, minDisplayTime: TimeDuration): void {
        this._mapComponentContainer!.reportProgress(
            this,
            new ProgressInfo(status),
            minDisplayTime
        );
    }

    async resume(): Promise<void> {
        this._paused = false;
        await this.onResume();
    }

    async unload(): Promise<void> {
        this._unloaded = true;
        await this.onUnload();
    }

    abstract update(sinceLastUpdate: TimeDuration): void;
}

export default Component;