import StandardHttpResponse from './models/standard_http_response';

class StandardHttpAPI {
    static async post({
        uri,
        body,
        bearer,
    }: {
        uri: string;
        body: any;
        bearer?: string;
    }): Promise<StandardHttpResponse> {
        try {
            const response = await fetch(uri, {
                method: 'POST',
                ...(body ? { body: JSON.stringify(body) } : {}),
                headers: {
                    ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
                    'Content-Type': 'application/json; charset=UTF-8',
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                return StandardHttpResponse.success(data);
            } else {
                const errorData = await response.json();
                return StandardHttpResponse.responseError(
                    response.status,
                    errorData.error,
                    errorData.message
                );
            }
        } catch (error) {
            return StandardHttpResponse.internetError();
        }
    }

    static async get({
        uri,
        bearer,
    }: {
        uri: string;
        bearer?: string;
    }): Promise<StandardHttpResponse> {
        return this._get({ uri, bearer });
    }

    private static async _get({
        uri,
        bearer,
    }: {
        uri: string;
        bearer?: string;
    }): Promise<StandardHttpResponse> {
        try {
            const response = await fetch(uri, {
                method: 'GET',
                headers: {
                    ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                return StandardHttpResponse.success(data);
            } else {
                const errorData = await response.json();
                return StandardHttpResponse.responseError(
                    response.status,
                    errorData.error,
                    errorData.message
                );
            }
        } catch (error) {
            return StandardHttpResponse.internetError();
        }
    }

    static async uploadFile({
        uri,
        formData,
        bearer,
    }: {
        uri: string;
        formData: FormData;
        bearer?: string;
    }): Promise<StandardHttpResponse> {
        try {
            const response = await fetch(uri, {
                method: 'POST',
                body: formData, // FormData for file upload
                headers: {
                    ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
                }, // Do not set Content-Type, fetch sets it automatically for FormData
            });

            if (response.status === 200) {
                const data = await response.json();
                return StandardHttpResponse.success(data);
            } else {
                const errorData = await response.json();
                return StandardHttpResponse.responseError(
                    response.status,
                    errorData.error,
                    errorData.message
                );
            }
        } catch (error) {
            return StandardHttpResponse.internetError();
        }
    }
}

export default StandardHttpAPI;
