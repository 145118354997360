import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';
import Pet from '../models/friend/pet';
import StandardHttpResponse from '../models/standard_http_response';

const API_URL = constants.API_URL;

class CustomizationApi {
    static async getSelectedCharacter(): Promise<string> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetSelectedCharacter`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.characterBuildString;
    }

    static async getPets(): Promise<Pet[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetPets`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.pets.map((json: any) =>
            Pet.fromJsonLowerCamelCase(json)
        );
    }

    static async deactivatePet(petId: string): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/DeactivatePet/${petId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });
    }

    static async activatePet(petId: string): Promise<Pet> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}customization/ActivatePet/${petId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Pet.fromJsonLowerCamelCase(response.response.activatedPet);
    }

    static async namePet(
        petId: string,
        name: string
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/NamePet/${petId}`,
            bearer: ValueContainer.token,
            body: { Name: name },
        });
    }

    /**
     * Checks if the user avatar should be updated based on metadata comparison.
     * @returns A promise resolving to a boolean indicating if the avatar should be updated.
     */
    static async shouldUpdateUserAvatar(): Promise<boolean> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/ShouldUpdateUserAvatar`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.shouldUpdate;
    }

    /**
     * Uploads a new user avatar.
     * @param file The avatar file to upload.
     * @returns A promise resolving to a success message or rejecting on failure.
     */
    static async uploadUserAvatar(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        const response = await StandardHttpAPI.uploadFile({
            uri: `${API_URL}customization/UploadUserAvatar`,
            formData,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.message;
    }
}

export default CustomizationApi;
