import ComponentErrorType from "../enums/component_error_type";

class ComponentError {
    private readonly _errorType: ComponentErrorType;
    private readonly _errorMessage: string;
    private readonly _helpWidget?: JSX.Element;
    private readonly _errorCode?: string;

    constructor(
        errorType: ComponentErrorType,
        errorMessage: string,
        helpWidget?: JSX.Element,
        errorCode?: string
    ) {
        this._errorType = errorType;
        this._errorMessage = errorMessage;
        this._helpWidget = helpWidget;
        this._errorCode = errorCode;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    get errorType(): ComponentErrorType {
        return this._errorType;
    }

    get helpWidget(): JSX.Element | undefined {
        return this._helpWidget;
    }

    get errorCode(): string | undefined {
        return this._errorCode;
    }
}

export default ComponentError;