import StandardHttpResponse from './models/standard_http_response';
import StandardHttpAPI from './standard_http_api';

import { constants } from '../utils/constants';

const API_URL = constants.API_URL;

class TelegramApi {
    static async validate(
        initData: string,
        username?: string,
        referrerTelegramId?: string
    ): Promise<StandardHttpResponse> {
        // Log event to analytics if needed

        return await StandardHttpAPI.post({
            uri: `${API_URL}telegram/validate`,
            // bearer: ValueContainer.token,
            body: {
                initData: initData,
                ...(username && { username: username }),
                ...(referrerTelegramId && { referrerTelegramId: referrerTelegramId }),
            },
        });
    }

    static async validateUsername(
        username: string
    ): Promise<StandardHttpResponse> {
        // Log event to analytics if needed

        return await StandardHttpAPI.post({
            uri: `${API_URL}telegram/validateUsername`,
            // bearer: ValueContainer.token,
            body: {
                username: username,
            },
        });
    }
}

export default TelegramApi;
