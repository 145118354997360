
import React from 'react';
import styles from './quest_item.module.css';
import coinIcon from '../../assets/images/navigation_tab/coin_stack_icon.png';


export interface Quest {
    title: string;
    description: string;
    action: () => void;
}

const QuestItem = ({title,description,action} : Quest) => {
  return (
    <div className={styles.questItem} onClick={action} >
      <h1 className={styles.questTitle}>{title}</h1>
      <p className={styles.questDescription}>{description}</p>
      <div className={styles.questSpacer} />
      <div className={styles.questFooter}>
        <img
          src={coinIcon}
          alt="Coin Stack"
          width="40"
          height="40"
        />
        <div style={{ width: 10 }} />
        <span className={styles.questReward}>1.14M BONK</span>
      </div>
    </div>
  );
};

export default QuestItem;