class DependencyLockInfo {
    private waitingFor: Function[];

    constructor(waitingFor: Function[]) {
        this.waitingFor = waitingFor;
    }

    get dependencies(): Function[] {
        return this.waitingFor;
    }

    addDependency(dependency: Function): void {
        this.waitingFor.push(dependency);
    }

    removeDependency(dependency: Function): void {
        this.waitingFor = this.waitingFor.filter(dep => dep !== dependency);
    }

    setDependencies(dependencies: Function[]): void {
        this.waitingFor = [...dependencies];
    }
}

export default DependencyLockInfo;