class ObservableValue<T> {
    private _value?: T;
    private _valueListeners: Array<(oldValue: T | undefined, newValue: T) => void> = [];

    constructor(initialValue?: T) {
        this._value = initialValue;
    }

    get value(): T {
        if (this._value === undefined) throw new Error("Value not initialized");
        return this._value;
    }

    get nullableValue(): T | undefined {
        return this._value;
    }

    set value(newValue: T) {
        const oldValue = this._value;
        this._value = newValue;
        this._valueListeners.forEach(listener => listener(oldValue, newValue));
    }

    addListener(listener: (oldValue: T | undefined, newValue: T) => void): void {
        this._valueListeners.push(listener);
    }

    removeListener(listener: (oldValue: T | undefined, newValue: T) => void): void {
        this._valueListeners = this._valueListeners.filter(l => l !== listener);
    }
}

export default ObservableValue;