export const constants = {
    API_URL: 'https://api.stepcoin.se/',
    INVITE_URL: 'https://t.me/stepcoin_web_bot',
};

export const secure_preferences_keys = {
    SECURE_PREFERENCES_KEY_COINS: 'coins',
    SECURE_PREFERENCES_KEY_GEMS: 'gems',
    SECURE_PREFERENCES_KEY_USERNAME: 'username',
    SECURE_PREFERENCES_KEY_TELEGRAM_ID: 'telegramId',
    SECURE_PREFERENCES_KEY_TOKEN: 'token',
    SECURE_PREFERENCES_KEY_E_RADIUS: 'expectedRadius',
    SECURE_PREFERENCES_KEY_TOTAL_STEPS: 'totalSteps',
    SECURE_PREFERENCES_KEY_TOTAL_PURCHASED_OFFERS: 'totalPurchasedOffers',
    SECURE_PREFERENCES_KEY_PHONE_NUMBER: 'phoneNumber',
    SECURE_PREFERENCES_KEY_EXPERIENCE: 'experience',
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL: 'currentLevel',
    SECURE_PREFERENCES_KEY_EXPERIENCE_UNTIL_NEXT_LEVEL:
        'experienceUntilNextLevel',
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL_EXPERIENCE: 'currentLevelExperience',
    SECURE_PREFERENCES_KEY_USER_TYPE: 'userType',
};

export const local_storage_keys = {
    LOCAL_STORAGE_KEY_CACHED_LOCALE: 'cachedLocale',
    LOCAL_STORAGE_KEY_CACHED_LOCALE_UPDATE_TIME: 'cachedLocaleUpdateTime',
};

export const component_error_codes = {
    TELEGRAM_REGISTRATION_INCOMPLETE: 'TELEGRAM_REGISTRATION_INCOMPLETE',
    CONTAINER_ALREADY_INITIALIZED: 'CONTAINER_ALREADY_INITIALIZED',
};
