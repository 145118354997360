class Completer<T> {
    private _promise: Promise<T>;
    private _resolve!: (value: T | PromiseLike<T>) => void;
    private _reject!: (reason?: any) => void;
    private _isCompleted: boolean = false;

    constructor() {
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    get promise(): Promise<T> {
        return this._promise;
    }

    get resolved(): boolean {
        return this._isCompleted;
    }

    resolve(value: T): void {
        if (!this._isCompleted) {
            this._resolve(value);
            this._isCompleted = true;
        }
    }

    reject(reason?: any): void {
        if (!this._isCompleted) {
            this._reject(reason);
            this._isCompleted = true;
        }
    }

    then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
    ): Promise<TResult1 | TResult2> {
        return this._promise.then(onfulfilled, onrejected);
    }
}

export default Completer;
