class StandardHttpResponse {
    private readonly _responseCode?: number;
    private readonly _internetError: boolean;
    private readonly _error?: string;
    private readonly _message?: string;
    private readonly _response?: any;

    constructor(
        responseCode?: number,
        internetError = false,
        error?: string,
        message?: string,
        response?: any
    ) {
        this._responseCode = responseCode;
        this._internetError = internetError;
        this._error = error;
        this._message = message;
        this._response = response;
    }

    static internetError(): StandardHttpResponse {
        return new StandardHttpResponse(undefined, true);
    }

    static responseError(responseCode: number, error?: string, message?: string): StandardHttpResponse {
        return new StandardHttpResponse(responseCode, false, error, message);
    }

    static success(response: any): StandardHttpResponse {
        return new StandardHttpResponse(200, false, undefined, undefined, response);
    }

    get isSuccess(): boolean {
        return this._responseCode === 200;
    }

    get hasResponseCode(): boolean {
        return this._responseCode !== undefined;
    }

    get responseCode(): number | undefined {
        return this._responseCode;
    }

    get isInternetError(): boolean {
        return this._internetError;
    }

    get hasError(): boolean {
        return this._error !== undefined;
    }

    get hasErrorMessage(): boolean {
        return this._message !== undefined;
    }

    get response(): any {
        return this._response;
    }

    get error(): string | undefined {
        return this._error;
    }

    get errorMessage(): string | undefined {
        return this._message;
    }
}

export default StandardHttpResponse;