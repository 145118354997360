class TelegramValidationResult {
    isValid: boolean;
    message?: string;
    token?: string;

    constructor({ isValid, message, token }: { isValid: boolean; message?: string; token?: string }) {
        this.isValid = isValid;
        this.message = message;
        this.token = token;
    }
}

export default TelegramValidationResult;
