class ProgressInfo {
    componentName!: string;
    componentLoadStatus: string;
    componentLoadIndex!: number;
    totalComponentCount!: number;

    constructor(componentLoadStatus: string) {
        this.componentLoadStatus = componentLoadStatus;
    }
}

export default ProgressInfo;